// Generated by Framer (aab6bf9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {eIl7zwJsu: {pressed: true}, HDQ_MJMOW: {pressed: true}};

const cycleOrder = ["eIl7zwJsu", "HDQ_MJMOW"];

const serializationHash = "framer-xplka"

const variantClassNames = {eIl7zwJsu: "framer-v-19ntahd", HDQ_MJMOW: "framer-v-lf6uq9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "eIl7zwJsu", Hover: "HDQ_MJMOW"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "eIl7zwJsu"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "eIl7zwJsu", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-19ntahd", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"eIl7zwJsu"} ref={ref ?? ref1} style={{backdropFilter: "blur(0px)", borderBottomLeftRadius: 29, borderBottomRightRadius: 29, borderTopLeftRadius: 29, borderTopRightRadius: 29, filter: "brightness(0.96) contrast(1.04) grayscale(1) invert(1)", WebkitBackdropFilter: "blur(0px)", WebkitFilter: "brightness(0.96) contrast(1.04) grayscale(1) invert(1)", ...style}} variants={{HDQ_MJMOW: {borderBottomLeftRadius: 46, borderBottomRightRadius: 46, borderTopLeftRadius: 46, borderTopRightRadius: 46}}} {...addPropertyOverrides({"eIl7zwJsu-pressed": {"data-framer-name": undefined}, "HDQ_MJMOW-pressed": {"data-framer-name": undefined}, HDQ_MJMOW: {"data-framer-name": "Hover"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xplka.framer-62s065, .framer-xplka .framer-62s065 { display: block; }", ".framer-xplka.framer-19ntahd { cursor: pointer; height: 39px; overflow: hidden; position: relative; width: 39px; will-change: var(--framer-will-change-override, transform); }", ".framer-xplka.framer-v-lf6uq9.framer-19ntahd { height: 75px; width: 75px; }", ".framer-xplka.framer-v-19ntahd.pressed.framer-19ntahd { height: 12px; width: 12px; }", ".framer-xplka.framer-v-lf6uq9.pressed.framer-19ntahd { height: 25px; width: 25px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 39
 * @framerIntrinsicWidth 39
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"HDQ_MJMOW":{"layout":["fixed","fixed"]},"bOHbsl6P9":{"layout":["fixed","fixed"]},"Dclj5NZrQ":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerQ7AzjBqhy: React.ComponentType<Props> = withCSS(Component, css, "framer-xplka") as typeof Component;
export default FramerQ7AzjBqhy;

FramerQ7AzjBqhy.displayName = "Cursor";

FramerQ7AzjBqhy.defaultProps = {height: 39, width: 39};

addPropertyControls(FramerQ7AzjBqhy, {variant: {options: ["eIl7zwJsu", "HDQ_MJMOW"], optionTitles: ["Variant 1", "Hover"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerQ7AzjBqhy, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})